<i18n locale="en" lang="yaml">
reset_password.title : "Reset Password"
reset_password.ok : "Reset"
field.username : "Username"
field.new_password: "New Password"
field.new_password.placeholder : "Please input new password"
field.new_password.hint : "@:common.password.pattern"
field.password.err.pattern : "@:common.password.pattern"
</i18n>
<i18n locale="th" lang="yaml" >
reset_password.title : "รีเซ็ตรหัสผ่าน"
reset_password.ok : "รีเซ็ต"
field.username : "ชื่อผู้ใช้งาน"
field.new_password: "รหัสผ่านใหม่"
field.new_password.placeholder : "กรุณาระบุรหัสผ่านใหม่ของผู้ใช้งานนี้"
field.new_password.hint : "@:common.password.pattern"
field.password.err.pattern : "@:common.password.pattern"
</i18n>

<template>
	<a-modal
		:visible="visible"
		:mask-closable="!loading"
		:title="$t('reset_password.title')"
		:ok-text="$t('reset_password.ok')"
		:width="400"
		:confirm-loading="loading"
		:cancel-button-props="{ props: { disabled: loading } }"
		@ok="handleOk"
		@cancel="handleCancel">
		<a-form layout='vertical' :form="formObj">
			<input name="fakeusernameremembered" class="hidden"/>
			<input type="password" name="fakepasswordremembered" class="hidden"/>

			<a-form-item :label="$t('field.username')">
				<span class="ant-form-text">
					{{user.username}}
				</span>
			</a-form-item>
			<a-form-item :label="$t('field.new_password')" :help="$t('field.new_password.hint')">
				<a-input-password
					v-decorator="[
						'new_password' ,
						{ rules: [
							{ required: true, message: $tt('validate.required','field.new_password') },
							{ pattern: /^(?=.*[a-zA-Z#$^+=!*()@%&])(?=.*\d).{6,40}$/ ,message: $t('field.password.err.pattern') },
						] },
					]"
					autocomplete="new-password"
					type="password"
					:placeholder="$t('field.new_password.placeholder')">
					<ion-icon slot="prefix" name="key-outline" class="anticon" />
				</a-input-password>
			</a-form-item>
		</a-form>
	</a-modal>
</template>

<script>
import HasAntdFormMixin from '@mixins/HasAntdFormMixin.vue'
import axios from "axios"
export default {
	mixins: [HasAntdFormMixin],
	props : {
		user : {
			type: null,
			default : () => []
		} ,
		visible :{
			type : Boolean,
			default : false
		}
	},
	data() {
		return {
			loading : false
		}
	} ,
	watch: {
		visible(newVal) {
			if (newVal) this.loading = false
		}
	} ,
	methods : {
		handleOk() {
			this.formObj.validateFields((err, values) => {
				if (!err) {
					this.loading = true
					axios.post("/api/users/"+this.user.id+"/reset-password",{
						'new_password' : values.new_password
					}).then((response) => {
						const user = response.data.data.user
						this.$emit('reset_password_success',user)
						this.formObj.resetFields()
					}).catch((error) => {
						this.formSubmitErrors(error)
					}).finally(() => {
						this.loading = false
					})
				} else {
					this.displayValidateErrorMessage()
				}
			});
		} ,
		handleCancel() {
			if (this.loading)
				return;
			this.formObj.resetFields()
			this.$emit('close')
		}
	}
}
</script>
