var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"visible":_vm.visible,"mask-closable":!_vm.loading,"title":_vm.$t('reset_password.title'),"ok-text":_vm.$t('reset_password.ok'),"width":400,"confirm-loading":_vm.loading,"cancel-button-props":{ props: { disabled: _vm.loading } }},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"layout":"vertical","form":_vm.formObj}},[_c('input',{staticClass:"hidden",attrs:{"name":"fakeusernameremembered"}}),_vm._v(" "),_c('input',{staticClass:"hidden",attrs:{"type":"password","name":"fakepasswordremembered"}}),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('field.username')}},[_c('span',{staticClass:"ant-form-text"},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.user.username)+"\n\t\t\t")])]),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('field.new_password'),"help":_vm.$t('field.new_password.hint')}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'new_password' ,
					{ rules: [
						{ required: true, message: _vm.$tt('validate.required','field.new_password') },
						{ pattern: /^(?=.*[a-zA-Z#$^+=!*()@%&])(?=.*\d).{6,40}$/ ,message: _vm.$t('field.password.err.pattern') },
					] },
				]),expression:"[\n\t\t\t\t\t'new_password' ,\n\t\t\t\t\t{ rules: [\n\t\t\t\t\t\t{ required: true, message: $tt('validate.required','field.new_password') },\n\t\t\t\t\t\t{ pattern: /^(?=.*[a-zA-Z#$^+=!*()@%&])(?=.*\\d).{6,40}$/ ,message: $t('field.password.err.pattern') },\n\t\t\t\t\t] },\n\t\t\t\t]"}],attrs:{"autocomplete":"new-password","type":"password","placeholder":_vm.$t('field.new_password.placeholder')}},[_c('ion-icon',{staticClass:"anticon",attrs:{"slot":"prefix","name":"key-outline"},slot:"prefix"})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }